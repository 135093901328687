import { Button } from 'cryptomus-aurora-kit';
import type { TButtonProps } from 'cryptomus-aurora-kit/dist/types/components/button';
import { useTranslation } from 'next-i18next';

import { useAuth } from 'services/query-hooks/use-auth';
import { getLocaleLink } from 'utils/get-locale-link';
import { CRYPTOMUS_APP_URL } from 'constants/app-url';

export type TSignUpButtonProps = Partial<TButtonProps<'a'>>;

export const SignUpButton = ({ ...props }: TSignUpButtonProps) => {
  const { isAuth } = useAuth();
  const { t, i18n } = useTranslation('common');

  return (
    <Button
      appearance='white'
      as='a'
      size='l'
      target='_blank'
      href={getLocaleLink(
        `${CRYPTOMUS_APP_URL}/${isAuth ? 'overview' : 'signup'}`,
        i18n.language
      )}
      {...props}
    >
      {isAuth ? t('Go to dashboard') : t('Sign Up')}
    </Button>
  );
};
