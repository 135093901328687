import React from 'react';

import clsx from 'clsx';

import InfiniteScroll from 'ui/infinite-scroll/infinite-scroll';

import type { IMedia } from 'constants/media-list';
import { MEDIA_LIST } from 'constants/media-list';

import mediaStyles from './media-block.module.scss';
import styles from 'app/styles/index.module.scss';

const MediaBlock: React.FC = () => (
  <div className={styles.media}>
    <div className={styles.infinite_container}>
      <InfiniteScroll
        items={MEDIA_LIST}
        renderItem={(item: IMedia) => (
          <a
            key={item.img}
            aria-label='Read morea about coin'
            className={clsx(styles.infinite_item, styles.main)}
            href={item.url}
            target='_blank'
          >
            <img
              alt={item.img}
              className={mediaStyles.image}
              src={`/img/press/${item.img}`}
            />
          </a>
        )}
      />
    </div>
  </div>
);

export default MediaBlock;
